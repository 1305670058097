import { useAuth, useFilter } from 'context'
import { Container, Typography, Input } from 'components'
import { normalizeString } from 'utils'

function Filters({
  showLines = false,
  multiLines = false,
  maxTagCount = 5,
  allCorporations = false,
  allDivisions = false,
  allUnits = false,
  withScheduleUnits = false,
  withUnscopedGetLines = false,
  hiddenFilters = [],
  ...props
}) {
  const { user: signedUser } = useAuth()
  const {
    lines,
    linesById,
    selectedCorporation,
    selectedDivision,
    selectedUnit,
    selectedLine,
    filteredUnits,
    filteredCorporations,
    filteredDivisions,
    onChange,
  } = useFilter({
    showLines,
    withScheduleUnits,
    allCorporations,
    allDivisions,
    allUnits,
    withUnscopedGetLines,
  })

  const filterFunc = (input, option) =>
    normalizeString(option.props.children.toLowerCase()).indexOf(
      normalizeString(input.toLowerCase()),
    ) >= 0

  const onChangeFilter = (key, value) =>
    onChange({
      [key]: value,
    })

  const filters = [
    {
      label: `Corporación:`,
      key: `selectedCorporation`,
      value: selectedCorporation,
      options: filteredCorporations,
      allowRoles: [1],
    },
    {
      label: `División:`,
      key: `selectedDivision`,
      value: selectedDivision,
      options: filteredDivisions,
      allowRoles: [1, 2],
    },
    {
      label: `Unidad:`,
      key: `selectedUnit`,
      value: selectedUnit,
      options: filteredUnits,
      allowRoles: [1, 2, 3],
    },
  ]

  const allowedFilters = filters.filter((filter) =>
    filter.allowRoles.includes(signedUser.roleId),
  )

  const visibleFilters = allowedFilters.filter(
    (filter) => !hiddenFilters.includes(filter.key),
  )

  return (
    <Container
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      {visibleFilters.map(({ label, key, value, options }, index) => (
        <Container
          width="33%"
          paddingX="1"
          justifyContent="start"
          alignItems="center"
          key={index}
        >
          <Typography
            fontWeight="2"
            color="primary.0"
            fontSize={1}
            marginRight="2"
          >
            {label}
          </Typography>
          <Input
            width="74%"
            type="select"
            labelKey="name"
            valueKey="id"
            value={value}
            onChange={(value) => onChangeFilter(key, value)}
            options={options}
            showSearch
            filterOption={filterFunc}
            optionFilterProp="children"
          />
        </Container>
      ))}
      {showLines && lines.length > 0 && (
        <Container
          width="100%"
          alignItems="center"
          marginTop={signedUser.roleId < 4 ? `2` : `0`}
        >
          <Typography
            fontWeight="2"
            color="primary.0"
            fontSize={1}
            marginLeft="3"
            marginRight="2"
          >
            Fila:
          </Typography>
          <Input
            type="select"
            labelKey="name"
            valueKey="id"
            showArrow
            mode={multiLines ? `multiple` : null}
            value={selectedLine}
            onChange={(selectedLine) => onChange({ selectedLine })}
            width="90%"
            options={lines.map((lineId) => linesById[lineId])}
            maxTagCount={maxTagCount}
          />
        </Container>
      )}
    </Container>
  )
}

export default Filters
